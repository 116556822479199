const todos = {
    'Download the app': {
        who: 'estrella',
        title: 'Link to download the app',
        description:
            'Add a link to download the app that will open the app store or display a QR code to download it',
    },
    'Deactivate google login button': {
        who: 'estrella',
        title: 'Deactive google login button',
        description:
            'The cors policy using the google button is not working. If the popup is closed the button will not be notified and will remain active. We need to find a way to deactivate the button when the popup is closed',
    },
    'Additional boarding fields': {
        who: 'estrella',
        title: 'Add additional boarding fields',
        description:
            'Add additional fields to the info after the boarding pass issuance',
    },
};

export default todos;
