import { Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

import BackgroundFlights from 'images/background-flights.png';

import S from 'theme/structure';
import { useEffect, useState } from 'react';

import { SabreMock } from 'component/api';

const {
    Body: { Container, Background },
} = S;

let backgroundImage = BackgroundFlights;

const style = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : '',
};

const sabreEasterEgg = {
    width: 200,
    height: 200,
    position: 'fixed',
    bottom: 0,
    right: 0,
};

const Component = () => {
    const [counter, setCounter] = useState(0);
    const [saberCounter, setSaberCounter] = useState(0);

    useEffect(() => {
        if (counter === 5) {
            const action =
                localStorage.getItem('showDebug') === 'show'
                    ? 'disable'
                    : 'enable';

            action === 'enable'
                ? localStorage.setItem('showDebug', 'show')
                : localStorage.removeItem('showDebug');

            setCounter(0);

            window.alert('Debug mode ' + action + 'd');
        }
    }, [counter]);

    useEffect(() => {
        if (saberCounter === 5) {
            const identifier = Cookies.get('identifier');
            if (!identifier) {
                alert('No identifier found');
                return;
            }
            setSaberCounter(0);
            SabreMock.requestConsent(identifier);
            alert(`Consent request flow start for ${identifier}`);
        }
    }, [saberCounter]);

    return (
        <Container id="body">
            <Background style={style} onClick={() => setCounter(counter + 1)} />
            <Outlet />
            <div
                style={sabreEasterEgg}
                onClick={() => setSaberCounter(saberCounter + 1)}
            ></div>
        </Container>
    );
};

export default Component;
