import * as Utils from './Utils';

const BASE_URL = `${process.env.REACT_APP_API}/public/${process.env.REACT_APP_API_VERSION}`;

export const requestConsent = (identifier) =>
    Utils.fetcher(`${BASE_URL}/mock/request-consent-mock-sabre`, {
        method: 'POST',
        body: JSON.stringify({
            consentTypeCodes: ['EML'],
            emailAddr: identifier,
        }),
    });
