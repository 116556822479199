import { useState } from 'react';
import S from 'theme/structure';

import TODOs from 'utils/TODOs';

const Component = ({ t, ...props }) => {
    const [display, setDisplay] = useState(true);

    const { title, description, who } = TODOs[t];

    return (
        <S.TODO.Container
            {...props}
            modifiers={
                localStorage.getItem('showDebug') === 'show' && display
                    ? []
                    : ['hidden']
            }
        >
            <S.TODO.Title>{title}</S.TODO.Title>
            <S.TODO.Description>{description}</S.TODO.Description>
            <S.TODO.Who>{who}</S.TODO.Who>
            <S.TODO.Hide
                size="sm"
                variant="outline-primary"
                onClick={() => setDisplay(false)}
            >
                Hide
            </S.TODO.Hide>
        </S.TODO.Container>
    );
};

export default Component;
