import { WebPubSub } from 'component/api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import S from 'theme/structure';

export const useWebPubSubControl = ({
    operation,
    hookId,
    waitingActionTitle = 'Please wait...',
    waitingProviderTitle = '',
    triggerText = 'Trigger',
    successMessage = 'Success!',
    waitingActionText = "Awaiting confirmation from your account. Please don't refresh the page.",
    actions = {},
    validation = () => undefined,
}) => {
    const args = useRef({});
    const websocket = useRef(null);
    const [triggered, setTriggered] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [listener, setListener] = useState(null);

    const getStorageKey = useCallback(
        () => `hook${hookId}`.toLowerCase(),
        [hookId]
    );

    const stopDiscovery = useCallback(() => {
        if (websocket.current) {
            websocket.current.close();
            websocket.current = null;
        }

        localStorage.removeItem(getStorageKey());
    }, [getStorageKey]);

    const abort = useCallback(() => {
        stopDiscovery();
        setLoading(false);
        setError(null);
        setCompleted(false);
        setTriggered(false);
    }, [stopDiscovery]);

    const readWebPubSubMessages = useCallback(async () => {
        const storageKey = getStorageKey();
        const id = localStorage.getItem(storageKey);
        if (id) {
            let pop, queued;
            do {
                pop = await WebPubSub.pop(id);
                const { message } = pop.value;
                queued = pop.queued;

                if (message) {
                    const { origin, type, payload } = message;
                    if (actions[origin] && actions[origin][type]) {
                        const { final, trigger } = actions[origin][type];
                        if (final) stopDiscovery();
                        trigger(payload);
                        if (final) setCompleted(true);
                    } else {
                        console.log('Unsuported message', origin, type);
                    }
                }
            } while (queued > 0);
        }
    }, [getStorageKey, actions, stopDiscovery]);

    useEffect(() => {
        if (!triggered) {
            const storageKey = getStorageKey();
            localStorage.removeItem(storageKey);
        }
    }, [triggered, getStorageKey]);

    useEffect(() => {
        if (completed) stopDiscovery();
    }, [completed, stopDiscovery]);

    useEffect(() => {
        return () => {
            abort();
        };
    }, [abort]);

    useEffect(() => {
        if (!listener) {
            const l = () => {
                if (document.visibilityState === 'visible')
                    readWebPubSubMessages();
            };
            setListener(l);

            document.addEventListener('visibilitychange', l);

            return () => {
                if (listener) {
                    document.removeEventListener('visibilitychange', listener);
                }
            };
        }
    }, [readWebPubSubMessages, listener]);

    const performOperation = useCallback(() => {
        const validationError = validation(args.current);
        if (validationError) {
            setValidationError(validationError);
            return;
        }

        setValidationError(null);
        setLoading(true);
        setError(null);
        setCompleted(false);
        setTriggered(true);

        const callbacks = {
            200: ({ value: { webpubsub, rid } }) => {
                if (webpubsub) {
                    const storageKey = getStorageKey();
                    localStorage.removeItem(storageKey);
                    localStorage.setItem(storageKey, rid);

                    websocket.current = new WebSocket(webpubsub);
                    websocket.current.onmessage = () => readWebPubSubMessages();
                } else {
                    setError(
                        'Something went wrong. Refresh the page and try again.'
                    );
                }
            },
        };

        return operation(args.current)
            .then((result) => {
                if (result?.status) {
                    const c = callbacks[result.status];
                    return c ? c(result) : result;
                }
                return result;
            })
            .then(() => setLoading(false))
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    }, [validation, operation, getStorageKey, readWebPubSubMessages]);

    const loadingTitle = loading ? waitingProviderTitle : waitingActionTitle;

    const controls = {
        loading: useMemo(
            () => (
                <>
                    <S.WebPubSub.Info.Container className="centered">
                        <S.WebPubSub.Info.Title>
                            {loadingTitle}
                        </S.WebPubSub.Info.Title>
                        <S.WebPubSub.Spinner animation="border" />
                    </S.WebPubSub.Info.Container>
                </>
            ),
            [loadingTitle]
        ),
        error: useMemo(
            () => <S.WebPubSub.Error>Error: {error}</S.WebPubSub.Error>,
            [error]
        ),
        success: useMemo(
            () => (
                <S.WebPubSub.Success className="centered">
                    {successMessage}
                </S.WebPubSub.Success>
            ),
            [successMessage]
        ),
        button: useMemo(
            () => (
                <S.WebPubSub.Info.Container className="centered">
                    <p>{validationError}</p>
                    <S.WebPubSub.Info.Button
                        className="button__rounded"
                        variant="primary"
                        disabled={loading}
                        loading={loading.toString()}
                        onClick={performOperation}
                        size="sm"
                    >
                        {triggerText}
                    </S.WebPubSub.Info.Button>
                </S.WebPubSub.Info.Container>
            ),
            [loading, performOperation, triggerText, validationError]
        ),
        waiting: useMemo(
            () => (
                <S.WebPubSub.Info.Container className="centered">
                    <p>{waitingActionText}</p>
                </S.WebPubSub.Info.Container>
            ),
            [waitingActionText]
        ),
    };

    let control;
    if (completed) {
        control = controls.success;
    } else if (error) {
        control = controls.error;
    } else if (loading) {
        control = controls.loading;
    } else if (triggered) {
        control = controls.waiting;
    } else {
        control = controls.button;
    }

    return {
        control,
        abort,
        triggered,
        completed,
        performOperation,
        setArgs: (a) => (args.current = a),
        args: args.current,
    };
};
